import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { update_example_with_index } from 'modules/api/market-api';
import history from 'modules/app/components/history'
import TextField from '@mui/material/TextField';

interface Props {
  selected: boolean;
}

const CustomDiv = styled.div<Props>`
  padding-top: 8px!important;
  border: ${props => { 
    if ( props.selected === true ) { 
      return '2px solid #1976d2' 
    } else {
      return 'none'
    } 
  }};
`;

export default function EditExampleDialog(props:any) {
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false)
  const [ edited_example, setEditedExample ] = React.useState<string>("");

  React.useEffect(() => {
    setOpen(props.open)

    if (props.open === true) {
      let edit_example_indx = props.edit_example_indx
      let temp_example_array = JSON.parse(JSON.stringify(props.products[props.selected_product_index].example_array))
      // setEditedExample(temp_example_array[edit_example_indx])
  
      let temp_example = JSON.parse(temp_example_array[edit_example_indx])
      setEditedExample(temp_example.messages[1].content[0].text)
    }
    
  }, [props.open])

  const handleClose = () => {
    props.setDelExampleIndex(99999999999)
    props.setOpen(false);
    setEditedExample('')
  };

  const handleSave = () => {
    try {
      (async () => {
        setIsLoading(true)

        let edit_example_indx = props.edit_example_indx
        let temp_example_array = JSON.parse(JSON.stringify(props.products[props.selected_product_index].example_array))
    
        let temp_example = JSON.parse(temp_example_array[edit_example_indx])
        temp_example.messages[1].content[0].text = edited_example
        temp_example_array[edit_example_indx] = JSON.stringify(temp_example)
       

        const delete_result = await update_example_with_index(props.products[props.selected_product_index].log_file_name, temp_example_array)

        if ( delete_result.status === 'success' ) {
          props.setProducts((prev: any[]) => {
            let next = JSON.parse(JSON.stringify(prev))

            next[props.selected_product_index].example_array = temp_example_array

            return next
          })
          setIsLoading(false)
          props.setOpen(false);
          setEditedExample('')
        } else if ( delete_result.status === 'error' ) {
          setIsLoading(false)
          props.setOpen(false);
          setEditedExample('')
          history.push('/admin/signin')
        }
      })()
    } catch ( e ) {
      // console.log(e)
    }
    
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedExample(e.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    
  }

  return (
        <>
          <div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
              fullWidth={true}
              maxWidth={'lg'}
            >
              {
                isLoading === true ? 
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={isLoading}
                  // onClick={handleClose}
                >
                  <CircularProgress color="inherit"/>
                  <p>&nbsp;&nbsp; Saving Example ...</p>
                </Backdrop>
                : <></>
              }
              <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Edit
              </DialogTitle>
              <DialogContent>
                {/* <DialogContentText>
                  This example will be deleted, are you okay?
                </DialogContentText> */}
                <TextField
                  autoFocus
                  margin="dense"
                  fullWidth
                  multiline
                  rows={15}
                  maxRows={15}
                  value={edited_example}
                  onChange={handleDescriptionChange}
                  onKeyDown={handleKeyDown}
                />
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <Button onClick={handleSave}>Save</Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
    
  );
}