import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles';
import styled1 from 'styled-components'
import { Grid, Box } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import history from 'modules/app/components/history'
import jwt_decode from 'jwt-decode'
import { read_all_examples, read_quiz_by_id } from 'modules/api/market-api'
import MobileHeader from './mobile_page/MobileHeader'
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteExampleDialog from './sub_components/DeleteExampleDialog';
import EditExampleDialog from './sub_components/EditExampleDialog';
import AdminHeader from 'shared/AdminHeader'
import Container from '@mui/material/Container';
import Test from 'modules/test'
import Toolbar from '@mui/material/Toolbar';
import './styles.css'


const Admin_Examples_For_Finetuning = (props: any) => {
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false)
  const [ isFirstLoading, setIsFirstLoading ] = React.useState<boolean>(false)

  const [ products, setProducts ] = React.useState<any>([])
  const [ best_url, setBestUrl ] = React.useState<string>('')
  const [ best_url_visited_users, setBestUrlVisitedUsers ] = React.useState<any>([])
  const [ question_arr, setQuestionArr ] = React.useState<any>([])
  const [ user_and_choice_arr, setUserAndChoiceArr ] = React.useState<any>([])
  const [ deleteUserOfQuizDialogOpen, setDeleteUserOfQuizDialogOpen ] = React.useState<any>(false)
  const [ editExampleDialogOpen, setEditExampleDialogOpen ] = React.useState<any>(false)
  const [ selected_product_index, setSelectedProductIndex ] = React.useState<number>(-1)
  const [ del_example_indx, setDelExampleIndex ] = React.useState<number>(99999999999)
  const [ edit_example_indx, setEditExampleIndex ] = React.useState<number>(99999999999)
  const [ example_array, setExampleArray ] = React.useState<any>([])

  useEffect(() => {
    setIsFirstLoading(true)

    if ( localStorage.getItem('jwtToken') != null && localStorage.getItem('userDbId') != null ) {
      const decoded:any = jwt_decode(JSON.stringify(localStorage.getItem('jwtToken')))

      if ( decoded.id == localStorage.getItem('userDbId') ) {
        (async () => {
          const result:any = await read_all_examples()
          // console.log(result)
          if( result.status === 'success' ) {
              let products = result.result
              products = products.map((product:any, index:number) => {
                  product = Object.assign(product, {active: false})
                  return product
              })
              setProducts(products)

              setIsFirstLoading(false)

          } else if ( result.status === 'error' ) {
            setIsFirstLoading(false)

              history.push('/admin/signin')
          }
        })()
      } else {
        setIsFirstLoading(false)

        history.push(`/admin/signin`)
      }

    } else {
      setIsFirstLoading(false)

      history.push(`/admin/signin`)
    }
  }, [])

  useEffect(() => {
    (async () => {
      try {
        // console.log('set products ... ')
        setIsLoading(true)
          let active_product_index = products.findIndex((ele:any) => ele.active === true)
          // console.log(active_product_index)
          if ( active_product_index !== -1 ) {

            let temp_example_array = products[active_product_index].example_array 
            if (temp_example_array !== undefined && temp_example_array.length !== 0) {
              // console.log(JSON.parse(temp_example_array[0]))
              temp_example_array = temp_example_array.map((ele:any, index:number) => {
                
                // let temp_ele = JSON.parse(ele)
                let temp_ele = JSON.parse(ele).messages[1].content[0].text
                // console.log(temp_ele)
                temp_ele = JSON.parse(temp_ele)
                temp_ele = JSON.stringify(temp_ele, null, 4)
                return temp_ele
              })
              setExampleArray(temp_example_array)
            } else {
              setExampleArray([])
            }           
            
          } else if ( active_product_index === -1 ) {

          }
        setIsLoading(false)
      } catch ( e ) {
        // console.log(e)
        history.push(`/admin/signin`)
      }
    })()
  }, [products])


  const goStartPage = () => {
    history.push(`/`)
  }

  const clicked_product = (active: boolean, index: number, product_name: string, product_id: string) => {
    (async () => {
      // console.log('click products ... ')
      // setUserAndChoiceArr([])
      // setQuestionArr([])
      if ( active === true ) {
        // console.log('active ... ')
      } else if ( active === false ) {
        setUserAndChoiceArr([])
        setQuestionArr([])

        setIsLoading(true)
        const result:any = await read_all_examples()
        // console.log(result)
        if( result.status === 'success' ) {
            let products = result.result

            products = products.map((product:any, index:number) => {
                product = Object.assign(product, {active: false})
                return product
            })

            for ( let i = 0; i < products.length; i++ ) {
              if ( products[i]._id === product_id ) {
                // products[i].active = true
                products[i] = Object.assign(products[i], {active: true})
              } else {
                products[i] = Object.assign(products[i], {active: false})
              }
            }

            setProducts(products)

            setIsLoading(false)

        } else if ( result.status === 'error' ) {
          setIsLoading(false)

          history.push('/admin/signin')
        }
        // setProducts((prev: any[]) => {
        //   let next = [...prev]

        //   let prev_active_index = next.findIndex(ele => ele.active === true)

        //   if ( prev_active_index !== -1 ) {
        //     next[prev_active_index].active = false
        //   }

        //   next[index].active = true
          
        //   return next
        // })
      }
    })()
    
  }

  const delete_one_example = (e:any, index:number) => {
    // console.log(user_name)
    e.stopPropagation()
    let active_product_index = products.findIndex((ele:any) => ele.active === true)
    setSelectedProductIndex(active_product_index)
    setDelExampleIndex(index)
    setDeleteUserOfQuizDialogOpen(true)
  }

  const edit_one_example = (e:any, index:number) => {
    // console.log(user_name)
    e.stopPropagation()
    let active_product_index = products.findIndex((ele:any) => ele.active === true)
    setSelectedProductIndex(active_product_index)
    setEditExampleIndex(index)
    setEditExampleDialogOpen(true)
  }

  return (
    <>
    <Box sx={{ display: 'flex' }}>
        {/* <AdminHeader /> */}
          <Test />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar sx={{minHeight: '48px!important'}}/>
            <Container sx={{ mt: 0, mb: 0, pl: '0!important', pr: '0!important', maxWidth: '100%!important' }}>
              <CustomGrid container spacing={0} className={"pt-0"}>
                <CustomGrid item xs={12}  className={"pt-0"}>
                  <Box sx={{ typography: 'body1' }}>
                    <div>
                      {
                        isFirstLoading === true ? 
                        <Backdrop
                          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                          open={isFirstLoading}
                          // onClick={handleClose}
                        >
                          <CircularProgress color="inherit"/>
                          <p>&nbsp;&nbsp; Loading ...</p>
                        </Backdrop> : <></>
                      }
                      {
                        isLoading === true ? 
                        <Backdrop
                          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                          open={isLoading}
                          // onClick={handleClose}
                        >
                          <CircularProgress color="inherit"/>
                          <p>&nbsp;&nbsp; Loading ...</p>
                        </Backdrop> : <></>
                      }
                      {/* <AdminHeader /> */}
                      {/* <HeaderContainer>
                        <CustomGrid container spacing={0} sx={{paddingTop: '10px'}}>
                          <CustomGrid item xs={2} className="grid-header-left header_logo" onClick={goStartPage} >
                            <img src={require('assets/logo_avatar3.png').default} style={{width: '52px!important'}} alt="" />
                            &nbsp;<span><BoldSpan>T</BoldSpan>op<BoldSpan>B</BoldSpan>est<BoldSpan>S</BoldSpan>ellers</span>
                          </CustomGrid>
                          <CustomGrid item xs={10} className="grid-flex-item-end grid-header-right" >
                            <CustomDiv className={"nav_link"}><span className={"nav_txt"}>Data</span></CustomDiv>
                            <CustomDiv className={"nav_link"}><span className={"nav_txt"}>Products</span></CustomDiv>
                            <CustomDiv className={"nav_link"}><span className={"nav_txt"}>Quiz</span></CustomDiv>
                            <CustomDiv className={"nav_link"}><span className={"nav_txt"}>Quiz Analyze</span></CustomDiv>
                            <CustomDiv className={"nav_link"}><span className={"nav_txt"}>Quiz Analyze Users</span></CustomDiv>
                          </CustomGrid>
                        </CustomGrid>
                        <Divider />
                      </HeaderContainer> */}
                      <CustomGrid container spacing={0}>
                        <CustomGrid item xs={12} className={'grid_header'}>
                            <MobileHeader 
                                products={products} 
                                clicked_product = {clicked_product}
                            />
                        </CustomGrid>
                        <CustomGrid item xs={12} md={2}  className={"grid_list"}>
                            <CustomDiv className={"list_div"}>
                              {
                                products.length > 0 && products.map((product:any, index:number) => (
                                    <CustomDiv key={index} className={"btn_div"}>
                                        <CustomDiv className={product.active === true ? "active_list_item btn_style" : "btn_style"} onClick={() => clicked_product(product.active, index, product.product_name, product._id)}>
                                            <CustomDiv className={"txt_div"}>
                                                {product.product_name}
                                            </CustomDiv>                                        
                                        </CustomDiv>
                                    </CustomDiv>
                                ))
                              } 
                            </CustomDiv>
                        </CustomGrid>
                        <CustomGrid item xs={12} md={10}>
                            {/* <CustomDiv className={"add_item_div"}>
                                
                            </CustomDiv> */}
                            <CustomDiv className={"content_div"}>  
                              <TableContainer component={Paper} style={{overflowX: 'initial', width: 'fit-content', minWidth: '100%'}}>
                               {
                                example_array.map((ele:any, index:number) => {
                                  return(
                                    <div className={'quiz_content_item_div'} key={index}>
                                      <CustomGrid container spacing={0}>
                                        <CustomGrid item xs={10} className={'mui_grid_flex'}>
                                          <div className={'quiz_content_item_title'}>Example {index + 1}: </div>
                                        </CustomGrid>
                                        <CustomGrid item xs={2} className={'mui_grid_flex mui_grid_item_end'}>
                                         
                                          <CustomModeEditOutlineIcon 
                                            fontSize="small" 
                                            className={'edit_icon'} 
                                            onClick={(e) => edit_one_example(e, index)} 
                                          />
                                           <CustomDeleteOutlineIcon
                                            fontSize="small"
                                            className={'delete_icon'}
                                            onClick={(e) => delete_one_example(e, index)}
                                          />
                                        </CustomGrid>
                                        
                                      </CustomGrid>
                                      <div style={{ padding: '15px 15px 0px', whiteSpace: 'pre-line' }}>
                                        {/* <pre> */}
                                          {/* {JSON.stringify(ele)} */}
                                          {ele}
                                        {/* </pre> */}
                                      </div>
                                    </div>
                                  ) 
                                    
                                })
                               }
                              </TableContainer>
                            </CustomDiv>
                        </CustomGrid>
                    </CustomGrid>
                    </div>  
                  </Box>
                  </CustomGrid>
              </CustomGrid>
            </Container>
          </Box>
        </Box>
     
    <DeleteExampleDialog 
        open={deleteUserOfQuizDialogOpen} 
        setOpen={setDeleteUserOfQuizDialogOpen} 
        selected_product_index={selected_product_index} 
        del_example_indx={del_example_indx} 
        setDelExampleIndex={setDelExampleIndex}
        products={products} 
        setProducts={setProducts} 
    />     
    <EditExampleDialog 
        open={editExampleDialogOpen} 
        setOpen={setEditExampleDialogOpen} 
        selected_product_index={selected_product_index} 
        edit_example_indx={edit_example_indx} 
        setDelExampleIndex={setEditExampleIndex}
        products={products} 
        setProducts={setProducts} 
    />     
    </>                                                               
  );
};

const CustomDeleteOutlineIcon = styled(DeleteOutlineIcon)`
  cursor: pointer;
  &.delete_icon: hover {
    color: #ff3100cf !important;
  }
`

const CustomModeEditOutlineIcon = styled(ModeEditOutlineIcon)`
  cursor: pointer;
  &.edit_icon: hover {
      color: #06d305!important;
  }
`

const HeadTableCell = styled1(TableCell)`
  font-weight: 700!important;
`

const CustomTableRow = styled1(TableRow)`
  &.user_info_row {
    background-color: #e2e9f7b8!important;
  }
`

const CustomTableCell = styled1(TableCell)`
  &.t_head {
    font-weight: 600!important;
  }
  &.question_t_head {
    color: #048ef3!important;
  }
  &.question_t_head_first_screener {
    color: #048aaa!important;
  }
`

const CustomDiv = styled1.div`
  &.input_div {
    display: flex;
    justify-content: center;
    padding-bottom: 10px!important;
  }
  &.answer_div {
    display: flex;
    justify-content: space-between;
  }
  &.txt_div {
    flex: 1 1 auto!important;
  }
  &.list_div {
    height: 85vh;
    overflow: auto;
    border-right: 1px solid darkgray!important;
    padding-right: 5px!important;
    padding-top: 5px!important;
  }
  &.content_div {
    height: 85vh;
    overflow: auto;
    padding-left: 5px!important;
    padding-right: 5px!important;
    padding-top: 5px!important;
  }

  &.btn_style {
      display: flex;
      padding: 8px 16px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
  }

  &.btn_div {
    border-bottom: 1px solid #c1bbbb22;
    margin: 2px 0px;
    transition: all 350ms ease-in-out;
  }
  
  &.btn_div:hover {
    background-color: #00000022!important;
  }

  &.active_list_item {
      background-color: #1e947e;
      color: white;
      transition: all 300ms ease-in-out;
  }
  &.delete_icon: hover {
    cursor: pointer;
    color: #ff3100cf!important;
  }
  &.min-width-0 {
    min-width: 0px!important;
    margin: 0 1px!important;
  }

  &.nav_link {
    // color: #fff;
    display: flex;
    justify-content: center;
    width: 200px;
    transition: 0.7s;
    text-decoration: none;
    padding: 0px 15px;
    border: 1px solid transparent;
    border-radius: 10px;
    margin: 0 10px;
    cursor: pointer;
  }

  &.nav_link:hover {
    // transform: scale(1.1);
    // font-size: 22px;
    border-color: #5989f161;
    box-shadow: 0 2px 5px 0 rgb(15 122 233 / 25%), 0 3px 10px 5px rgb(13 115 245 / 12%) !important;
    .nav_txt {
      color: #2563eb;
      letter-spacing: 1px;
    }
  }

  &.nav_link .nav_txt{
    font-size: 20px;
    font-weight: 500;
    transition: 0.5s;
  }

  // &.nav_link .nav_txt:hover{
  //   color: #2563eb;
  // }

  
`

const CustomGrid = styled1(Grid)`
  background-color: white!important;
  padding: 2px;
  margin: 0px!important;

  &.mui_grid_flex {
    display: flex !important;
    flex-direction: column;
  }

   &.mui_grid_item_end {
    flex-direction: row !important;
    justify-content: end;
  }

  &.grid_header {
    @media (min-width: ${screenSizes.mediaM}px) {
        display: none!important;
    }
  }
  &.grid_list {
    @media (max-width: ${screenSizes.mediaM}px) {
        display: none!important;
    }
  }
  .header_logo{
    display: flex;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
  }

  img {
    width: 25px;
    height: 25px;
  }

  .grid-flex-item-end {
    display: flex;
    justify-content: end;
  }

  .grid-flex-item-start {
    display: flex;
    justify-content: start;
  }

  .grid-header-left {
   
  }

  .grid-header-right {
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-right: 105px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-right: 155px;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-right: 185px;
    }
  }

  .custom-paper {
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 300px;
    }
    @media (min-width: ${screenSizes.mediaXL}px) {
      width: 355px;
    }
  }

  .pt-0 {
    padding-top: 0px!important;
  }

  .grid_main{
    display: flex;
    justify-content: start;
  }

  .main_grid {
    padding: 0px 50px 0px!important;
    display: flex;
    justify-content: center;
  }
  .circle_loading_main_grid {
    padding: 50px 50px 0px!important;
    display: flex;
    justify-content: center;
  }

  .circle_loading_text_div {
    display: flex!important;
    justify-content: center!important;
    font-size: 22px!important;
    font-weight: 500!important;
    padding: 20px 0!important;
    text-align:center!important;
  }

  .progress_div {
    display: flex;
    justify-content: center;
    // flex-direction: column;
  }

  .analyze_result_div {
    width: 100%;
  }
`

const HeaderContainer = styled1.div`
  @media (max-width: ${screenSizes.mediaM}px) {
    display: none;
  }
`

const BoldSpan = styled1.span`
  font-weight: 700;
`

export default Admin_Examples_For_Finetuning