import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { generate_img_via_openai_with_only_prompt } from 'modules/api/market-api';
import history from 'modules/app/components/history'

interface Props {
  selected: boolean;
}

const CustomDiv = styled.div<Props>`
  padding-top: 8px!important;
  border: ${props => { 
    if ( props.selected === true ) { 
      return '2px solid #1976d2' 
    } else {
      return 'none'
    } 
  }};
`;

export default function GenerateAiImageDialog(props:any) {
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ edited_prompt, setEditedPrompt ] = React.useState<string>("");
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false)

  React.useEffect(() => {
    setOpen(props.open)
    if (props.prompt !== undefined) {
      setEditedPrompt(props.prompt)
    } else {
      setEditedPrompt("")
    }
  }, [props.open])

  const handleClose = () => {
    // props.setEditProductIndex(-1)
    setEditedPrompt("")
    props.setOpen(false);
  };

  const handleGenerate = () => {
   
      (async () => {
        try {
          // console.log(edited_prompt)
          if ( edited_prompt.trim() !== '' && edited_prompt !== '' && edited_prompt !== undefined && edited_prompt !== null ) {
            setIsLoading(true)
            // let generate_result = await generate_img_via_openai_with_only_prompt(props.editProductId, props.edit_quiz_index, edited_prompt)
            let generate_result = await generate_img_via_openai_with_only_prompt(edited_prompt)
    
            if ( generate_result.status === 'success' ) {
              props.setFiles([{preview: generate_result.image_url}])
              props.setImageLocationFlag('openai')
              props.setImageSaveBtnShow(true)
              props.setPrompt(edited_prompt)

              setIsLoading(false)
              props.setOpen(false);
            } else if ( generate_result.status === 'error' ) {
              setIsLoading(false)
              props.setOpen(false);
              history.push('/admin/signin')
            }
          }
        } catch ( e ) {
          // console.log(e)
          setIsLoading(false)
          props.setOpen(false);
          history.push('/admin/signin')
        }
      })()
    
    
  };

  const handlePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedPrompt(e.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    
  }

  return (
        <>
          <div>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
              {
                isLoading === true ? 
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                    // onClick={handleClose}
                >
                    <CircularProgress color="inherit"/>
                    <p>&nbsp;&nbsp; Generating Image ...</p>
                </Backdrop>
                :<></>
              }
              <DialogTitle>Generate Image With Openai</DialogTitle>
              <DialogContent>
                  
                <DialogContentText>
                  Prompt:                  
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  fullWidth
                  multiline
                  rows={15}
                  maxRows={15}
                  value={edited_prompt}
                  onChange={handlePromptChange}
                  onKeyDown={handleKeyDown}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleGenerate}>Generate</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
    
  );
}